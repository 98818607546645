//Images

import '../img/amazing-bot_nota-1.png';
import '../img/amazing-bot_nota-2.png';
import '../img/amazing-bot_nota-3.png';
import '../img/amazing-bot_nota-4.png';
import '../img/amazing-bot_nota-5.png';
import '../img/favicon.ico';
import '../img/favicon-192x192.png';
import '../img/favicon-32x32.png';
import '../img/favicon-16x16.png';
import '../img/apple-touch-icon.png';
import '../img/sti_logo_branco.png';
import '../img/sig_lay_sti-log_h30.png';
import '../img/system.png';
import '../img/atendente.png';
import '../img/bot_sigaa_screen.png'
import "../sass/app-dark.sass";

//CSS
require('bootstrap-icons/font/bootstrap-icons.css');
require('datatables.net-dt/css/jquery.dataTables.css');

//Sass


//JavaScript
window.$ = require('jquery');
window.bootstrap = require("bootstrap")
window.DataTable = require("datatables.net")

require('./index.js');